<template>
  <div class="flex justify-center">
    <div class="w-80 mt-6">
      <b-field label="Name">
        <b-input
          type="text"
          placeholder="name"
          maxlength="25"
          v-model="newuser.name"
        ></b-input>
      </b-field>
      <b-field label="Gender" class="mb-5">
        <b-radio v-model="newuser.gender" name="gender" native-value="Male">
          Male
        </b-radio>
        <b-radio v-model="newuser.gender" name="gender" native-value="Female">
          Female
        </b-radio>
        <b-radio v-model="newuser.gender" name="gender" native-value="Others">
          Others
        </b-radio>
      </b-field>
      <b-field label="Mobile Number">
        <b-input
          type="text"
          placeholder="8754612584"
          maxlength="10"
          v-model="newuser.mobilenumber"
        ></b-input>
      </b-field>
      <b-field label="Email">
        <b-input
          type="email"
          placeholder="@primetute.com"
          maxlength="30"
          v-model="newuser.email"
        ></b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          type="Password"
          placeholder="Password"
          icon-pack="far"
          v-model="newuser.password"
          password-reveal
        />
      </b-field>
      <div class="pt-8">
        <b-button
          icon-pack="far"
          icon-left="sign-in-alt"
          class="btn"
          @click="signup"
          expanded
        >
          Sign In
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signup",
  data() {
    return {
      newuser: {
        name: '',
        gender:'',
        mobilenumber: '',
        email: '',
        password: '',
      },
    };
  },
  methods: {
    signup() {
      this.$store.dispatch("signup", {
        name: this.newuser.name,
        gender:this.newuser.gender,
        mobilenumber: this.newuser.mobilenumber,
        email: this.newuser.email,
        password: this.newuser.password,
      });
        this.newuser.name = '',
        this.newuser.gender ='',
        this.newuser.mobilenumber = '',
        this.newuser.email = '',
        this.newuser.password = ''
    },
  },
};
</script>

<style scoped></style>
